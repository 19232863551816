import * as React from 'react';
import SingleColumnLayout from '../layouts/singleColumnLayout/SingleColumnLayout';
import Grid from '../components/soul/layout/grid/Grid';
import {GridDensity} from '../components/soul/layout/grid/GridDensity';
import GridItem from '../components/soul/layout/grid/GridItem';
import {GridItemSize} from '../components/soul/layout/grid/GridItemSize';
import Sign from '../components/soul/layout/sign/Sign';
import {IconSize} from '../components/soul/layout/sign/IconSize';
import Flag from '../components/soul/layout/flag/Flag';

import {graphql} from 'gatsby';
import estecoWorldData from '../content/index/esteco-world-content.json';
import whyAttendData from '../content/index/why-attend-content.json';
import promotedSpeakers from '../content/index/promoted-speakers-content.json';
import umLinksData from '../content/links/um-links-content.json';
import InternalLink from '../components/soul/navigation/InternalLink';
import SpeakersRow, {Speaker} from '../components/speaker/SpeakersRow';
import Img from 'gatsby-image';
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox';

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */
interface IndexPageProps {
    data: {
        file: {
            childIndexJson: {
                images: FluidImage[];
            }
        };
        allFile: {
            nodes: Speaker[]
        }
    }
}


const IndexPage: React.FC<IndexPageProps> = ({data}) => {
    const images = data.file.childIndexJson.images;
    const lastName = (node: Speaker) => node.childMarkdownRemark.frontmatter.lastName;
    let speakers = data.allFile.nodes.filter(item => item.childMarkdownRemark && item.childMarkdownRemark.frontmatter.promoted)
        .sort((a, b) => lastName(a).localeCompare(lastName(b)));
    if (speakers.length > 5) {
        speakers = speakers.slice(0, 5);
    }

    return (
        <SingleColumnLayout>
            <SimpleReactLightbox>
                <div className="centered-column">
                    {/*Esteco World*/}
                    <h1 className="page-title">{estecoWorldData.title}</h1>
                    {estecoWorldData.subtitles.map((subtitle, index) =>
                        <p key={`esteco-world-subtitles-${index}`} className="soul-space-stack-top-xs soul-font-size-m" dangerouslySetInnerHTML={{__html:subtitle}}></p>
                    )}
                    <div className="soul-space-stack-bottom-2-xl"/>
                    <Gallery images={images}/>

                    {/*Why Attend*/}
                    <div className="soul-space-stack-top-2-xl"/>
                    <Flag reverse>
                        <InternalLink to={umLinksData.links.why_attend.href} enabled={umLinksData.links.why_attend.enabled}
                                      className='a-button  a-button--ghost-link'>
                            <Sign iconSize={IconSize.ICON_L} icon={whyAttendData.link.icon}
                                  label={whyAttendData.link.label} reverse/>
                        </InternalLink>
                        <h2 className="soul-font-size-2-xl h-text-bold">{whyAttendData.title}</h2>
                    </Flag>
                    <div className="soul-space-stack-top-xl"/>
                    <Grid density={GridDensity.WIDE}>
                        {whyAttendData.sections.map(section =>
                            <GridItem key={`${section.id}`}
                                      size={GridItemSize.ONE_THIRD}
                                      additionalClasses='l-palm--one-whole soul-space-stack-bottom-2-xl'>
                                <div className="ew-icon"><span className={`a-icon a-icon--xl ${section.icon}`}/></div>
                                <h3 className="soul-space-stack-top-s soul-font-size-l h-text-bold">{section.title}</h3>
                                <p className="soul-space-stack-top-2-xs soul-font-size-m"  dangerouslySetInnerHTML={{__html: section.description}} />
                            </GridItem>
                        )}
                    </Grid>

                    <div className="soul-space-stack-top-2-xl"/>
                    <div className="ew-divider"/>
                    <div className="soul-space-stack-bottom-2-xl"/>
                </div>
            </SimpleReactLightbox>
        </SingleColumnLayout>
    );
};

const Gallery: React.FC<{ images: FluidImage[] }> = ({images}) => {
    // https://github.com/michelecocuccio/simple-react-lightbox#options
    const lightboxOptions = {
        buttons: {
            showAutoplayButton: false,
            showDownloadButton: false,
            showFullscreenButton: false,
            showThumbnailsButton: false
        },
        caption: {
            showCaption: false
        }
    };
    return (
        <>
        <Grid density={GridDensity.NARROW} additionalClasses="l-palm--one-whole">
            <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                <a target="_blank" href="https://drive.google.com/file/d/16951BCOvCt-80bW58NTsUr2uJEoVUeQX/view"><Img className="h-cursor-pointer" fluid={images[0].childImageSharp.fluid}/></a>
            </GridItem>
            <GridItem size={GridItemSize.ONE_HALF} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                <a target="_blank" href="https://www.accelevents.com/e/esteco-users-meeting-2022#agenda"><Img className="h-cursor-pointer" fluid={images[1].childImageSharp.fluid}/></a>
            </GridItem>
        </Grid>
        <Grid density={GridDensity.NARROW} additionalClasses="soul-space-stack-bottom-l  l-palm--one-whole">
            <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                <a target="_blank" href="https://drive.google.com/file/d/1bDk8HfKbLpz1NJYYi2V3t-JNL_UANfT-/view"><Img className="h-cursor-pointer" fluid={images[2].childImageSharp.fluid}/></a>
            </GridItem>
            <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                <a target="_blank" href="https://www.accelevents.com/e/esteco-users-meeting-2022#agenda"><Img className="h-cursor-pointer" fluid={images[3].childImageSharp.fluid}/></a>
            </GridItem>
            <GridItem size={GridItemSize.ONE_THIRD} additionalClasses="l-palm--one-whole  soul-space-stack-bottom-s">
                <a target="_blank" href="https://www.accelevents.com/e/esteco-users-meeting-2022#agenda"><Img className="h-cursor-pointer" fluid={images[4].childImageSharp.fluid}/></a>
            </GridItem>
        </Grid>
        </>
    );
};

export const pageQuery = graphql`
    query RetrieveSpeakers {
        file(name: {eq: "esteco-world-content"}) {
            childIndexJson {
                images {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }

        allFile(
            sort: {fields: childMarkdownRemark___frontmatter___weight},
            filter: {relativeDirectory: {eq: "speakers"}}
        ) {
            nodes {
                relativePath
                id
                childMarkdownRemark {
                    frontmatter {
                        weight
                        firstName
                        lastName
                        promoted
                        avatar {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        jobTitle
                        organization
                    }
                }
            }
        }
    }
`;

export default IndexPage;
